import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import PlusIcon from "../../../assets/icons/plus.svg"
import MinusIcon from "../../../assets/icons/minus.svg"

const FAQAccordion = ({ questions }) => {
  const [questionNameClicked, setQuestionNameClicked] = React.useState()

  const setActiveIcon = (key) => {
    if (questionNameClicked === key) setQuestionNameClicked()
    else setQuestionNameClicked(key)
  }

  let card = []
  let eventKey = 1
  for (let key in questions) {
    card.push(
      <Card key={eventKey} className="shadow-sm mb-3">
        <Accordion.Toggle
          as={Card.Header}
          className="bg-white border-bottom-0"
          eventKey={eventKey}
          onClick={() => setActiveIcon(key)}
          style={{ cursor: "pointer" }}
        >
          <div className="row no-gutters align-items-center">
            <div className="col-10">
              <h6 className="mb-0 text-muted">{questions[key].question}</h6>
            </div>
            <div className="col-1 ml-auto text-right text-primary">
              {key === questionNameClicked ? (
                <MinusIcon style={{ width: "24px", height: "24px" }} />
              ) : (
                <PlusIcon style={{ width: "24px", height: "24px" }} />
              )}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body className="text-muted fw-l pt-3">
            {questions[key].answer}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
    eventKey++
  }

  return <Accordion>{card}</Accordion>
}

export default FAQAccordion
