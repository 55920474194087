import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"
import How from "../components/pages/approach/How"
import Faq from "../components/pages/approach/FAQ"
import Contact from "../components/pages/index/Contact"

const ApproachPage = () => (
  <StaticQuery
    query={graphql`
      {
        approachLgScreen: file(relativePath: { eq: "kanban-small.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        approachSmScreen: file(relativePath: { eq: "kanban.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 960, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Our Approach"
          keywords={[`approach`]}
          description="A great product requires fierce execution by a skilled team who welcomes consistent client feedback. Let us show you how we get it done."
        />
        <PageHeader
          imageSm={data.approachSmScreen.childImageSharp.gatsbyImageData}
          imageLg={data.approachLgScreen.childImageSharp.gatsbyImageData}
          title="Approach"
          subTitle="Discover our approach to application development"
          overlay="darker"
        />
        <main>
          <How />
          <Faq />
          <Contact
            heading={
              <>
                Want to <span className="text-primary">hire us</span> or learn
                more about our approach?
              </>
            }
          />
        </main>
      </Layout>
    )}
  />
)

export default ApproachPage
