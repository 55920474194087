import React, { Component } from "react"
import loadable from "@loadable/component"

class How extends Component {
  state = {
    component: "consult",
  }

  componentChangeHandler = (component) => {
    this.setState({ component })
  }

  render() {
    const { component } = this.state
    const spinner = (
      <div className="lazy-load m-auto pt-3 pb-5">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    )

    let SelectedComponent

    if (component === "consult") {
      SelectedComponent = loadable(() => import("./Consult"))
    } else if (component === "stories") {
      SelectedComponent = loadable(() => import("./Stories"))
    } else if (component === "wireframes") {
      SelectedComponent = loadable(() => import("./Wireframes"))
    } else if (component === "iterations") {
      SelectedComponent = loadable(() => import("./Iterations"))
    } else if (component === "release") {
      SelectedComponent = loadable(() => import("./Release"))
    }

    return (
      <section id="index-contact">
        <div className="container mb-5">
          <div className="row">
            <div className="col-lg-9 m-auto text-center ">
              <h2 className="text-capitalize">How we build applications</h2>
              <p className="text-muted fw-l">
                A great product requires fierce execution by a skilled team who
                welcomes consistent client feedback. Let us show you how we get
                it done.
              </p>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row text-center pb-5">
            <div className="col-12">
              <div className="radio-button-group">
                <button
                  className={component === "consult" ? "active" : null}
                  onClick={() => this.componentChangeHandler("consult")}
                >
                  Consult
                </button>
                <button
                  className={component === "stories" ? "active" : null}
                  onClick={() => this.componentChangeHandler("stories")}
                >
                  Stories
                </button>
                <button
                  className={component === "wireframes" ? "active" : null}
                  onClick={() => this.componentChangeHandler("wireframes")}
                >
                  Wireframes
                </button>
                <button
                  className={component === "iterations" ? "active" : null}
                  onClick={() => this.componentChangeHandler("iterations")}
                >
                  Iterations
                </button>
                <button
                  className={component === "release" ? "active" : null}
                  onClick={() => this.componentChangeHandler("release")}
                >
                  Release
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <SelectedComponent
              clickHandler={this.componentChangeHandler}
              fallback={spinner}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default How
