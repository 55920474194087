import React from "react"
import {Link} from "gatsby"
import FAQAccordion from "./FAQAccordion"

const faq = () => {
  const leftAccordionQuestions = {
    question1: {
      question: "What do I need to bring or prepare before our first meeting?",
      answer:
        (<><p>One of the biggest steps before building a successful application is to know your competition, your customer, and your objectives. This will allow us to target our development efforts in the right areas to help make your application a success.</p><p> Therefore, to get the most out of the consultation, we suggest you come to the meeting with specific details and examples of your competition, customer, and business objectives.</p><p className="mb-0">If you're just starting out and don't know where to start, we highly recommend you <Link className="btn-inline" to="/services/startup-solutions">download our free Idea Validation Blueprint</Link>. It will guide you in answering these questions.</p></>),
    },
    question2: {
      question:
        "What happens if something goes wrong and I need to terminate the contract?",
      answer:
        (<><p>Even though we'd be sad to see you go, we understand that things happen that are out of your control. Since Agile projects are built in iterations, we would hand over all the working iterations up to that point and terminate the contract.</p><p className="mb-0"> You can then pick up the project in the future without any major loss on the application's development cycle.</p></>),
    },
    question3: {
      question:
        "What if my time and budget don't allow for the exact application that I want?",
      answer:
        (<><p>It is often the case that not all stories are included within the first release of an application due to time and budget constraints. This is not necessarily a bad thing.</p><p className="mb-0"> Successful applications initially focus on their top 1-3 features and must perform those functions extremely well. We will work with you to narrow down which stories are the most crucial in providing your customers with the core experience of your vision.</p></>),
    },
    question4: {
      question:
        "What happens if I want to make changes midway through the project?",
      answer:
        (<><p>Applications are constantly evolving and our team welcomes change. When a story is changed or added, we'll work with you to decide whether we're changing the scope of the project or whether you are flexible on time and budget.</p><p className="mb-0">The more common approach is to be flexible on scope which means if you add a story, another will be removed. This approach keeps projects on time and on budget.</p></>),
    },
  }
  const rightAccordionQuestions = {
    question1: {
      question: "What if I don't have all the answers during the consultation?",
      answer:
        "The consultation is simply a discovery process where we get to learn more about your project and how we can work together to make your application a success. There are no right or wrong answers.",
    },
    question2: {
      question:
        "How involved do I need to be throughout the application development process?",
      answer:
        "Successful applications are built around customer feedback. Since we deliver working iterations every 1-2 weeks, your consistent feedback ensures that we are moving in the right direction. A hands off approach is possible but not recommended.",
    },
    question3: {
      question:
        "What makes your approach unique compared to other development companies?",
      answer:
        (<><p>Our industry is plagued by incomplete projects and overspent budgets. This is why we developed our approach with the help of project management veterans who specialize in web and mobile application development.</p><p className="mb-0"> We took their years of experience and broke it down into simple, logical steps so we can deliver the best application within the alloted time and budget.</p></>),
    },
    question4: {
      question:
        "Why use Agile Project Management compared to other management processes?",
      answer:
        "Agile Project Management is proven to deliver working applications faster and more consistently than any other management style. Agile also helps development companies deploy these applications on time and within budget.",
    },
  }
  return (
    <section id="faq">
      <div className="container ">
        <div className="row ">
          <div className="col-lg-9 m-auto text-center">
            <h2>Frequently Asked Questions</h2>
            <p className="text-muted fw-l pb-4">
              We understand that you may have some questions on our approach
              so we compiled a list of frequently asked questions to help you
              out. Please don't hesitate to <Link className="btn-inline" to="/contact"> contact us</Link> for further information.
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-6">
            <FAQAccordion questions={leftAccordionQuestions} />
          </div>
          <div className="col-md-6">
            <FAQAccordion questions={rightAccordionQuestions} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default faq
